@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Roboto:wght@100;400;700&display=swap');

/* 
  Colors
*/
:root {
    --grey: #9e9e9e;
    --bg-white: #f9f9f9;
    --black: #212121;
    --highlight: #ffff00;

    --box-shadow-deep: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

html {
    font-size: 62.5%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: normal;
}

body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    position: relative;
}

/* RESETS */
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    height: 100%;
    width: 100%;
}

::placeholder {
    color: var(--grey);
    font-size: 1.2rem;
}

/* ======================================== */
.navbar {
    background: var(--bg-white);
    height: 5rem;
    line-height: 5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 10;
}
.brand {
    margin: 0 0 0 5rem;
    font-size: 2.2rem;
    font-weight: 400;
}
.menu-box {
    margin: 0 5rem 0 0;
    font-weight: 400;
}
.menu-items {
    margin: 0 5px 0 5px;
    font-size: 1.6rem;
    display: inline-block;
    text-align: center;
}
.menu-link {
    transition: all 0.2sec;
    cursor: pointer;
}
.menu-link:hover {
    color: blue;
}

@media only screen and (max-width: 600px) {
    .brand {
        margin: 0 0 0 1rem;
    }
    .menu-box {
        margin: 0 2rem 0 0;
    }
}

/* ======================================== */
.main-body-container {
    width: 100vw;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
}

section {
    height: 100vh;
    min-height: 600px;
    scroll-snap-align: start;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

/* ======================================== */
.home {
    background: url(./assets/computerbg2.webp);
    background-size: cover;
    background-position: center bottom;
    padding: 5rem;
    position: relative;
}

.home::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.home-content {
    position: relative;
    width: 50vw;
    color: white;
    border-radius: 0.5rem;
    font-size: clamp(1.6rem, 3vw, 2.2rem);
    overflow: hidden;
}

.home-p {
    margin-bottom: 2rem;
}

.intro-one {
    font-size: clamp(2rem, 4vw, 3.5rem);
    color: var(--highlight);
}

/* ======================================== */

.projects {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-scroll-container {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    height: 100%;
}

.project-scroll-container::-webkit-scrollbar {
    height: 0px;
}
.project-scroll-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
.project-scroll-container::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #efecec;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.project-scroll-container::-webkit-scrollbar-thumb:horizontal:hover {
    background: #b0afaf;
}

.project-content-container {
    scroll-snap-align: center;
    height: 100%;
    width: 100vw;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.project-content-container::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
}

#proj1 {
    background: url(./assets/klipsdemopic.png);
    background-size: cover;
    background-position: center center;
}

#proj2 {
    background: url(./assets/Kicks_Moment.jpg);
    background-size: cover;
    background-position: center center;
}
#proj3 {
    background: url(./assets/news1_Moment.jpg);
    background-size: cover;
    background-position: center center;
}

.project-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid var(--grey);
}

.project-link {
    color: var(--highlight);
    font-size: 1.8rem;
    width: 10rem;
    padding: 1rem 0.5rem;
    text-decoration: underline;
}
.project-link-long {
    width: 24rem;
}

.project-card {
    color: #fff;
    width: 80rem;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: var(--box-shadow-deep);
    display: flex;
    flex-direction: column;
    position: relative;
}

.proj-next-btn,
.proj-back-btn {
    background-color: var(--highlight);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    position: relative;
    box-shadow: var(--box-shadow-deep);
}
.proj-back-btn a,
.proj-next-btn a {
    height: 5rem;
}

.proj-next-btn {
    margin-left: 1rem;
}

.proj-back-btn {
    margin-right: 1rem;
    transform: rotate(180deg);
}

/* .proj-next-btn img {
    aspect-ratio: 1/1;
    height: 3rem;
} */

.klips-proj {
    background: url('assets/klipsdemopic.png');
}

.kicks-proj {
    background: url('assets/Kicks_Moment.jpg');
}

.news-proj {
    background: url('assets/news1_Moment.jpg');
}

.proj-img-container {
    width: 100%;
    aspect-ratio: 16/9;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.proj-text {
    padding: 1rem 2rem;
    background-color: rgba(0, 0, 0, 0.3);
}

.proj-title {
    font-size: clamp(2rem, 3.5vw, 2.5rem);
    margin-bottom: 1rem;
    color: var(--highlight);
    display: flex;
    align-items: center;
}

.visit-btn {
    border: 2px solid var(--highlight);
    border-radius: 0.5rem;
    padding: 0 0.5rem;
    font-size: 1.6rem;
    margin-left: 2rem;
}

.proj-p {
    font-size: clamp(1.6rem, 3.5vw, 2.2rem);
    margin: 1rem 0;
}

.play-btn {
    background-color: var(--highlight);
    border-radius: 100px;
    height: 7rem;
}

/* ============================================= */

.contact {
    background: url(./assets/contactbg.jpg);
    background-size: cover;
    background-position: center top;
    padding: 5rem;
    position: relative;
}

.contact::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
}

.icon {
    margin-left: 0.5rem;
    height: 3rem;
    border-radius: 0.5rem;
    border: 0.2rem solid white;
    background-color: white;
}
.icon-mail {
    margin-left: 0.5rem;
    height: 3rem;
    border-radius: 0.5rem;
    border: 0.1rem solid white;
    background-color: white;
}

.contact-text {
    font-size: clamp(1.6rem, 3.5vw, 2.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    color: #fff;
}

.contact-link-box {
    color: yellow;
    margin-left: 0.5rem;
    cursor: pointer;
}
.contact-link {
    display: flex;
    align-items: center;
    text-decoration: underline;
}

/* ================================================ */

.modal-canvas {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.75);
}

.close-modal-x {
    color: #fff;
    display: flex;
    justify-content: flex-end;
}

.close-modal-x span {
    cursor: pointer;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.video-container {
    overflow: hidden;
    /* padding-top: 56.25%; */
    width: 60vw;
    aspect-ratio: 16/9;
    position: relative;
    border: 0.5rem solid #fff;
    border-radius: 0.5rem;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

@media screen and (max-width: 950px) {
    .proj-next-btn,
    .proj-back-btn {
        position: absolute;
    }

    .proj-next-btn {
        margin-left: 0;
        top: 40%;
        right: 1rem;
    }

    .proj-back-btn {
        margin-right: 0;
        top: 40%;
        left: 1rem;
        z-index: 1;
    }
}

@media screen and (max-width: 830px) {
    .home-content {
        width: 100vw;
    }
    .project-card {
        width: 90vw;
    }

    .video-container {
        width: 95vw;
    }

    .contact {
        background-position: center left 20%;
    }
}

@media screen and (orientation: landscape) and (max-width: 900px) {
    .main-body-container {
        scroll-snap-type: none;
    }

    .video-container {
        width: 70vw;
    }

    .proj-next-btn {
        top: 40%;
    }

    .proj-back-btn {
        top: 40%;
    }
}

/* ======================================== */

.m-left {
    margin-left: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

/* this class needs to be in the end of the file. */
.no-show-modal {
    display: none;
}

.hidden {
    visibility: hidden;
}
